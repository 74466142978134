import React from "react";

import "./style.scss";
import { Contact } from "../../components";
import { getImage } from "../../helper";

export const SoldLayout = ({ title = "", contact = "", puppies = [] }) => (
  <div className="sold">
    {title && <h1>{title}</h1>}
    {puppies?.length ? (
      <div className="puppies">
        {puppies?.map((puppy, index) => (
          <div className="puppy" key={index}>
            <img src={getImage(puppy?.picture)} alt={puppy?.name} />
            <p>{puppy?.name}</p>
          </div>
        ))}
      </div>
    ) : null}
    <Contact image={contact} />
  </div>
);
